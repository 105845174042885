<template>
  <nav>
    <!-- Desktop Navigation bar -->
    <v-toolbar extended
      src="~@/assets/images/navbar-background.jpg"
    >

      <v-app-bar-nav-icon
        class="grey--text hidden-md-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="menu-title" size="80">
        Katrin Elsen
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        text
        color="primary"
        class="menu-item hidden-md-and-down"
        v-for="navitem in navitems"
        :key="navitem.display"
        :to="navitem.link"
        >{{ navitem.display }}</v-btn>
    </v-toolbar>

    <!-- Mobile Navigation drawer -->
    <v-navigation-drawer
      app
      src="@/assets/images/navbar-background.jpg"
      v-model="drawer"
    >
      <v-list dense nav>
        <v-list-title class="menu-title">Katrin Elsen</v-list-title>
        <v-divider></v-divider>

        <v-list-item
          v-for="navitem in navitems"
          :key="navitem.display"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-menu-right</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <v-btn text :to="navitem.link">{{ navitem.display }}</v-btn>
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="drawer = false">
            <v-icon>mdi-chevron-triple-left</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
  export default {
    name: 'Navbar',
    data: function() {
      return {
        'drawer': false,
        'navitems': [
          {
            'display': 'Bilder',
            'link': '/showgalleries/bilder',
          },
          {
            'display': 'Fotos',
            'link': '/showgalleries/fotos',
          },
          {
            'display': 'Videos',
            'link': '/showvideo',
          },
          {
            'display': 'Vitae',
            'link': '/vitae',
          },
          {
            'display': 'Austellungen',
            'link': '/exhibitions',
          },
          {
            'display': 'Presse',
            'link': '/press',
          },
          {
            'display': 'Kontakt',
            'link': '/contact'
          },
        ]
      }
    }
  }
</script>

<style>
@font-face {
  font-family: "Source Sans Pro";
  src: url(~@/assets/fonts/SourceSansPro-Regular.ttf);
}

.v-toolbar__title {
  font-size: 2.1em !important;
}

.menu-title {
  margin-top: 1em;
  font-family: "Source Sans Pro";
  font-weight: bold;
  letter-spacing: 4px;
}

.menu-item {
  font-family: "Source Sans Pro";
  font-weight: bold;
}
</style>
