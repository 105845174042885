<template>
  <v-container row fluid>
    <v-card outlined>
      <v-card-title primary-title class="justify-center">Über meine Werke</v-card-title>
      <v-card-text align="left">
        In meinen Werken geht es meist um Widersprüche: kindlich/unabhängig, Mensch/Tier, Natur/Kultur, archaisch/erotisch. Als Thema habe ich oft den Menschen, seine Rolle in der Gesellschaft, die Klonerie, die Wirklichkeit und den Sentimentalismus. Meine Malerei ist eine Mischung von Realismus, Surrealismus und Symbolik. Ich benutze den Affen als Sinnbild der menschlichen Eigenschaften. Auf Grund seiner Ähnlichkeit mit dem Menschen eignet der Primat sich als Karikatur desselben. Er entlarvt dessen Wahrheit, die grausamen, zärtlichen, naiven und verspielten Seiten. Als Wesen zwischen Mensch und Tier symbolisiert er den Menschen der seinen Trieben folgt und ist gleichzeitig albern und weise.
      </v-card-text>
    </v-card>

    <v-card class="mt-5" outlined>
      <v-card-title primary-title class="justify-center">Impressum</v-card-title>
      <v-card-text align="left">
        Katrin Elsen ist verantwortlich für den Inhalt dieser Seite. Alle Inhalte dieser Webseite, insbesondere Abbildungen und Texte , unterliegen dem Copyright von Katrin Elsen. Die Weitergabe, Verwendung, kommerzielle Nutzung, Speicherung und Druck sämtlicher Inhalte ist nur mit vorhergehender schriftlicher Genehmigung gestattet.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style>
</style>

