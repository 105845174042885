<template>
  <v-container row fluid>
    <v-layout wrap class="ma-10">
      <v-flex xs12 sm12 md4>
        <contact-card />
      </v-flex>
      <v-flex xs12 sm12 md8>
        <about />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import About from '@/components/About.vue'

export default {
  name: 'ShowContact',
  components: {
    About,
    ContactCard,
  },
}
</script>
