import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Color Pallett used
// https://colorhunt.co/palette/8350

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        primary: '#dbd8e3',
        secondary: '#5c5470',
        accent: '#352f44',
      }
    },
    icons: {
      iconfont: 'mdi',
    },
  }
});
