import Meta from 'vue-meta'
import ShowContact from '../views/ShowContact.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    redirect: '/contact',
    component: ShowContact,
  },
  {
    path: '/showgalleries/:catName',
    name: 'showGalleries',
    component: () => import('../views/ShowGalleries.vue')
  },
  {
    path: '/showgallery/:catName/:galleryName',
    name: 'showGallery',
    component: () => import('../views/ShowGallery.vue')
  },
  {
    path: '/showvideo',
    name: 'showVideo',
    component: () => import('../views/ShowVideo.vue'),
  },
  {
    path: '/exhibitions',
    name: 'exhibitions',
    component: () => import('../views/ShowExhibitions.vue')
  },
  {
    path: '/vitae',
    name: 'vitae',
    component: () => import('../views/ShowVitae.vue')
  },
  {
    path: '/press',
    name: 'press',
    component: () => import('../views/ShowPress.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: ShowContact,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
