<template>
  <v-app>
    <div id="nav">
      <navbar />
    </div>
    <router-view />
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import ogImage from "./assets/images/contact.jpg"

export default {
  name: 'App',
  components: {
    Navbar
  },
  metaInfo: {
    title: 'Katrin Elsen',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Werke von Katrin Elsen' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'Katrin Elsen Homepage' },
      { property: 'og:site_name', content: 'Katrin Elsen Homepage' },
      { property: 'og:description', content: 'Werke von Katrin Elsen' },
      { property: 'og:image', content: 'https://www.katrinelsen.org' + ogImage },
      { property: 'og:url', content: 'https://www.katrinelsen.org/vitae' },
      { property: 'og:type', content: "website" },
      { property: 'twitter:url', content: "https://www.katrinelsen.org" },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Katrin Elsen' },
      { name: 'twitter:description', content: 'Werke von Katrin Elsen' },
      { name: 'twitter:image', content: 'https://www.katrinelsen.org' + ogImage },
    ]
  }
};
</script>

<style>
html, body {
  background-color: #121212;
}
</style>
