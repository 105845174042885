<template>

  <v-card
    max-width="375"
    class="mx-auto"
    outlined
  >
    <v-img
      src="~@/assets/images/contact.jpg"
      height="300px"
      dark
    >
      <v-row class="space-between justify-center">
        <v-card-title class="white--text ">
          <div class="display-1">Katrin Elsen</div>
        </v-card-title>
      </v-row>
    </v-img>

    <v-list two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">mdi-phone</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>(+352) 661 853 234</v-list-item-title>
          <v-list-item-subtitle>Mobile</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">mdi-email</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>info@katrinelsen.org</v-list-item-title>
          <v-list-item-subtitle>Informal</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">mdi-map-marker</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>21, Hanner Bra</v-list-item-title>
          <v-list-item-subtitle>L-6231 Bech Luxembourg</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">mdi-cake-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>16. Dezember 1985</v-list-item-title>
          <v-list-item-subtitle>in Luxemburg</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'ContactCard'
  }
</script>

<style>
</style>
